/*
####################################################
VARIABLES
####################################################
*/

// default
$black: black;
$white: white;
$gray: grey;
$bg: #f1f1f1;

// palette
$primary: #555555;
$secondary: #222222;
$third: #d9d9d9;
$fourth: #fafafa;
$fifty: #888888;
$sixty: #fffcfc;
$seventy: #1e024c;
$eighth: '';
$ninth: '';
$tenth: '';
$eleventh: '';
$twelfth: '';
$thirteenth: '';